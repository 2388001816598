let apiBaseUrl = "https://" + window.location.hostname + "/api/reliabilities/";

export const getBankImage = id => {
  let srcImg;
  if (id == 602) srcImg = "images/602.png";
  else if (id == 603) srcImg = "images/603.png";
  else if (id == 604) srcImg = "images/604.png";
  else if (id == 605) srcImg = "images/605.png";
  else if (id == 606) srcImg = "images/606.png";
  else if (id == 607) srcImg = "images/607.png";
  else if (id == 608) srcImg = "images/608.png";
  else if (id == 609) srcImg = "images/609.png";
  else if (id == 618) srcImg = "images/618.png";
  else if (id == 625) srcImg = "images/625.png";
  else if (id == 626) srcImg = "images/626.png";
  else if (id == 627) srcImg = "images/627.png";
  else if (id == 628) srcImg = "images/628.png";
  else if (id == 629) srcImg = "images/629.png";
  else if (id == 630) srcImg = "images/630.png";
  else srcImg = apiBaseUrl + id + "/image";
  return srcImg;
};

export const bancosNAI = [
  // { "nome": "Banese", "logo": "/images/banese.png", "url": "https://www.banese.com.br" },
  // { "nome": "Banestes", "logo": "/images/banestes.png", "url": "https://www.banestes.com.br" },
  // { "nome": "Itaú", "logo": "/images/itau.png", "url": "https://www.itau.com.br" },
  // { "nome": "Sicredi", "logo": "/images/sicredi.png", "url": "https://www.sicredi.com.br" },
  // { "nome": "Banpará", "logo": "/images/banpara.png", "url": "https://www.banpara.com.br" },
  // { "nome": "Crefisa", "logo": "/images/crefisa.png", "url": "https://www.crefisa.com.br" },
  // { "nome": "Banco Inter", "logo": "/images/inter.png", "url": "https://www.bancointer.com.br" },
  // { "nome": "Banco Seguro", "logo": "/images/seguro.png", "url": "https://www.bancoseguro.com.br" },
  // { "nome": "Original", "logo": "/images/original.png", "url": "https://www.original.com.br" },
  // { "nome": "BMG", "logo": "/images/bmg.png", "url": "https://www.bancobmg.com.br" },
  // { "nome": "C6 S.A", "logo": "/images/c6.png", "url": "https://www.c6bank.com.br" },
  // { "nome": "BMB", "logo": "/images/bmb.png", "url": "https://www.mercantildobrasil.com.br" },
  // { "nome": "Safra", "logo": "/images/safra.png", "url": "https://www.safra.com.br" },
  // { "nome": "Banco Pan", "logo": "/images/pan.png", "url": "https://www.bancopan.com.br" },
  // { "nome": "Daycoval", "logo": "/images/daycoval.png", "url": "https://www.daycoval.com.br" }
];
