import React, { useEffect } from 'react';
import { useAppState, useQuery } from '../AppContext';
import { changeInfoTela } from '../layout/actions';
import Card from '../../commons/Card'
import { changeReliabilityInfo, retrieveUserReliabilities, setModalAcquiredReliabilityVisibility, setModalHowToObtainReliabilityVisibility, viewUserReliabilitiesById } from './actions';
import { useHistory } from 'react-router-dom';
import ModalHowToObtainReliability from './ModalHowToObtainReliability';
import ModalAcquiredReliability from './ModalAcquiredReliability';
import MyLevel from './levels/MyLevel';
import MyReliabilities from './levels/MyReliabilities';
import { Link } from 'react-router-dom';

const componentDidMount = (dispatch) => () => {
  window.scrollTo(0, 0);
  dispatch(
    changeInfoTela({
      title: 'Como aumentar o nível da sua conta',
      breadcrumbs: [
        { label: "Nível da conta", link: "/" },
        { label: 'Aumentar o nível da sua conta' }]
    })
  )
  dispatch(retrieveUserReliabilities());
}

export default function QueroEsteNivel() {

  const [state, dispatch] = useAppState();
  useEffect(componentDidMount(dispatch), []);

  let query = useQuery();

  let [{ reliabilities }] = useAppState();
  let lvls = reliabilities.reliabilities;
  let allMyReliabilities = [];
  const lvlsId = [];
  let maxLevel = 0;

  let apiBaseUrl = "https://" + window.location.hostname + "/api/reliabilities/";
  let history = useHistory();

  const viewUserReliability = rel => {
    dispatch(setModalAcquiredReliabilityVisibility(true));
    dispatch(viewUserReliabilitiesById({ rel }));
  };

  const ListOrObtainReliability = rel => {
    if (allMyReliabilities.includes(rel.reliability.id)) {
      viewUserReliability(rel);
    } else if (rel.reliability.urlAquisicao !== undefined) {
      history.push(rel.reliability.urlAquisicao);
    } else {
      openHowToObtainReliabilityModal(rel.reliability);
    }
  };

  const openHowToObtainReliabilityModal = reliability => {
    dispatch(setModalHowToObtainReliabilityVisibility(true));
    dispatch(changeReliabilityInfo({ reliability }));
  };

  return (
    <>
      {/* Obtendo as minhas reliabilities */}
      {lvls && lvls
        .filter(l1 => l1.anyAcquired)
        .map(l2 => {
          for (let i = 0; i < l2.categories.length; i++) {
            l2.categories[i].reliabilities
              .filter(r1 => r1.acquired)
              .map(r2 => allMyReliabilities.push(r2.reliability.id))
          }
        })}

      {/* Obtendo o maior nível */}
      {lvls && lvls.filter(l1 => l1.anyAcquired).map(l2 => {
        lvlsId.push(parseInt(l2.level.id))
        maxLevel = Math.max(...lvlsId)
      })}

      <span className="page-title">Aumentar o nível da sua conta gov.br</span>
      <span className='page-description'>
        {(query.get("nivel") === 'ouro' || query.get("nivel") === 'prata') && <>Você pode aumentar o nível da sua conta escolhendo a opção mais adequada para você, clicando em algum dos cards abaixo. Se a sua conta é ouro, ela já possui o nível máximo de segurança.</>}
        {query.get("nivel") === 'bronze' && <>Se a sua conta gov.br foi criada por um dos meios abaixo, ela é nível <b>bronze</b>. Este é o <b>nível mais básico</b> da conta gov.br. Para aumentar seu nível, escolha um dos cards nas sessões <Link to="/quero-este-nivel?nivel=ouro">ouro</Link> ou <Link to="/quero-este-nivel?nivel=prata">prata</Link>.</>}
      </span>

      <span className="sub-page-title">Seu nível atual</span>
      <MyLevel maxLevel={maxLevel} />

      {
        query.get("nivel") === 'ouro' &&
        <>
          <p className='ouro'>OURO</p>
          <span className='page-sub-description'>Para aumentar o nível da sua conta gov.br para o <b>nível ouro</b>, utilize uma das opções abaixo:</span>
          <div className="home-cards">
            {lvls && lvls.find(l => l.level.id == 3).categories.map(cat => (
              cat.reliabilities.map(rel => (
                <Card
                  key={rel.reliability.id}
                  onClick={() => ListOrObtainReliability(rel)}
                  imgSrc={apiBaseUrl + rel.reliability.id + "/image"}
                  description={rel.reliability.descricao}
                />
              ))
            ))}
          </div>
          <p className='links-outros-niveis'>Ver opções de validação para o nível <Link to="/quero-este-nivel?nivel=prata">prata</Link> ou <Link to="/quero-este-nivel?nivel=bronze">bronze</Link>.</p>
        </>
      }

      {
        query.get("nivel") === 'prata' &&
        <>
          <p className='prata'>PRATA</p>
          <span className='page-sub-description'>Para aumentar o nível da sua conta gov.br para <b>prata</b>, utilize uma das opções abaixo:</span>
          <div className="home-cards">
            {lvls && lvls.find(l => l.level.id == 2).categories.map(cat => (
              cat.reliabilities.filter(filteredRel => (filteredRel.reliability.id < 601))
                .map(rel => (
                  <Card
                    key={rel.reliability.id}
                    onClick={() => ListOrObtainReliability(rel)}
                    imgSrc={apiBaseUrl + rel.reliability.id + "/image"}
                    description={rel.reliability.descricao}
                  />
                ))))}
            <Card
              key="bancos"
              linkTo={"/bancos-credenciados"}
              imgSrc={apiBaseUrl + "601/image"}
              description="Validação dos seus dados via <b>internet banking</b> de um <b>banco credenciado</b>"
            />
          </div>
          <p className='links-outros-niveis'>Ver opções de validação para o nível <Link to="/quero-este-nivel?nivel=ouro">ouro</Link> ou <Link to="/quero-este-nivel?nivel=bronze">bronze</Link>.</p>
        </>
      }

      {
        query.get("nivel") === 'bronze' &&
        <>
          <p className='bronze'>BRONZE</p>
          <span className='page-sub-description'>Se a sua conta gov.br foi criada por um dos meios abaixo, ela é nível <b>bronze</b>. Este é o <b>nível mais básico</b> da conta gov.br.</span>
          <div className="home-cards">
            {lvls && lvls.find(l => l.level.id == 1).categories.map(cat => (
              cat.reliabilities.filter(rel => !( ((rel.reliability.id === "504") && !allMyReliabilities.includes("504")) || 
                                                 ((rel.reliability.id === "502") && !allMyReliabilities.includes("502")) ))
                .map(rel => (
                  <Card
                    key={rel.reliability.id}
                    onClick={() => ListOrObtainReliability(rel)}
                    imgSrc={apiBaseUrl + rel.reliability.id + "/image"}
                    description={rel.reliability.descricao}
                  />
                ))
            ))}

          </div>
          <p className='links-outros-niveis'>Ver opções de validação para o nível <Link to="/quero-este-nivel?nivel=ouro">ouro</Link> ou <Link to="/quero-este-nivel?nivel=prata">prata</Link>.</p>
        </>
      }

      <MyReliabilities reliabilities={reliabilities} levels={lvls} open={true} />

      <ModalHowToObtainReliability />
      <ModalAcquiredReliability />
    </>
  )

}