import { API_PATH } from '../../../commons/constants'
import fetchApi from '../../../commons/fetch-api'

export const createSocialSecurityToken = () => async (dispatch, state) => {
  var response = await fetchApi(API_PATH.endpoints.postCreateSocialSecurityToken, { method: 'POST' })  
  if(response){
    window.location = response.socialSecurityKbaRequestUrl
  }
}

export const socialSecurityRedirectEndpoint = (token) => async (dispatch, state) => {

  const pathRequest = API_PATH.endpoints.getSocialSecurityRedirectEndpoint.replace(':tokenValue', token)
  const response = await fetchApi(pathRequest, { method: 'GET' })
  if(response){
    const errorsPathRequest = API_PATH.endpoints.getSocialSecurityRedirectEndpointErrors
    const resp2 = await fetchApi(errorsPathRequest, { method: 'GET' })
    if(resp2){
      window.location = '/'      
    }
  }
}

