import React, { useState } from "react";
import ModalAcquiredReliabilities from "../ModalAcquiredReliabilities";
import AcquiredReliabilities from "./AcquiredReliabilities";

export default function MyReliabilities({ reliabilities, levels, open = false }) {

  const [myRelibilitiesView, setMyReliabilitiesView] = useState(open ? 'block' : 'none')
  const [chevron, setChevron] = useState(open ? 'down' : 'right')

  const showMyReliabilities = () => {
    myRelibilitiesView === 'none'
      ? setMyReliabilitiesView('block')
      : setMyReliabilitiesView('none')

    chevron === 'right'
      ? setChevron('down')
      : setChevron('right')
  }

  return (
    <>
      <span type="button" className="collapsible" onClick={showMyReliabilities}>
        <i className={`fa fa-chevron-${chevron}`} style={{ marginRight: 10, fontWeight: 500 }}></i>Saiba quais validações você possui
      </span>
      <div className="content" style={{ display: myRelibilitiesView }}>
        <p>Você já realizou as seguintes validações de dados para criar ou aumentar o nível da sua conta gov.br:</p>
        {reliabilities && (
          <div className="my-reliabilities">
            {levels && levels
              .filter(l => l.anyAcquired)
              .map(l => <AcquiredReliabilities key={l.level.id} level={l} />)
            }
            <ModalAcquiredReliabilities />
          </div>
        )}
      </div>
    </>
  )

}