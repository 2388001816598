import React from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function Breadcrumb() {
  const [{ layout }] = useAppState();
  const accountsHost =
    "contas" + window.location.hostname.replace("confiabilidades", "");
  const accountsUrl = "https://" + accountsHost;
  return (
    <ul className="breadcrumb">
      <li key="initial">
        <a href={`${process.env.REACT_APP_HOME_URL}`}>
          <FontAwesomeIcon icon={faHome} />
        </a>
      </li>
      <li>
        <a href={`${accountsUrl}/seguranca`}>Segurança</a>
      </li>
      {layout.breadcrumbs &&
        layout.breadcrumbs.map((b, i) => (
          <li
            key={b.label}
            className={layout.breadcrumbs.length - 1 === i ? "is-active" : ""}
          >
            {b.link && <Link to={b.link}>{b.label}</Link>}
            {!b.link && b.label}
          </li>
        ))}
    </ul>
  );
}

export default Breadcrumb;
