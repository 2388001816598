import React from "react";
import { getBankImage } from "../../../commons/util";
import { useAppState } from "../../AppContext";
import { setModalAcquiredReliabilitiesVisibility, viewUserReliabilitiesByLevel } from "../actions";

export default function AcquiredReliabilities({ level }) {

  const [state, dispatch] = useAppState();

  const viewUserReliabilities = levelId => {
    dispatch(setModalAcquiredReliabilitiesVisibility(true));
    dispatch(viewUserReliabilitiesByLevel({ levelId }));
  };

  return (
    <div className="reliability-card-inactive" onClick={() => viewUserReliabilities(level.level.id)}>
      <h4>{level.level.titulo}</h4>
      {level.categories && level.categories
        .filter(cat => cat.anyAcquired)
        .map(cat => (
          <div className="acquiredCategory" key={cat.category.id}>
            {cat.reliabilities && cat.reliabilities
              .filter(rel => rel.acquired)
              .map(rel => <img key={rel.reliability.id} src={getBankImage(rel.reliability.id)}></img>)}
          </div>
        ))}
    </div>
  )
}