import "./../../components/commons/style.css";
import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import { useAppState } from "../AppContext";
import Init from "../init/Init";

function AppConfiabilidades() {
  const [{ layout, logged_user }] = useAppState();

  return (
    <>
      <Header />
      <div className="container">
        {!logged_user.id && <Init />}
        {logged_user.id && (
          <>
            <Content />
          </>
        )}
        <LoadingOverlay
          active={layout.loading}
          spinner
          text="Aguarde. Estamos processando suas informações"
          styles={{
            overlay: base => ({
              ...base,
              position: "fixed"
            })
          }}
        />
      </div>
      <Footer />
    </>
  );
}

export default AppConfiabilidades;
