import React from 'react'
import { Link } from 'react-router-dom'

export default function Silver({ level }) {
  return (
    <div className='card-level'>
      <p>
        NÍVEL <b>PRATA</b>
      </p>
      <img alt='Nível' src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`} />
      <ul className='advantages'>
        <li>
          <b>Gratuito</b>
        </li>
        <li>Conta única</li>
        <li>Login sem senha</li>
        <li>Gerenciar uso de dados</li>
        <li>Prova de vida</li>
        <li>Verificação em duas etapas</li>
        <li>Compartilhar documentos</li>
        <li>Assinatura eletrônica</li>
        <li>Acesso a todos os serviços</li>
        <li className='dont'>Nível máximo de segurança</li>
      </ul>
      {level >= 2 ? (
        <Link className='action-secondary' to='/quero-este-nivel?nivel=prata'>
          Ver nível
        </Link>
      ) : (
        <Link className='action-primary' to='/quero-este-nivel?nivel=prata'>
          Aumentar nível
        </Link>
      )}
    </div>
  )
}
