import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState } from '../../AppContext'

const componentDidMount = (dispatch) => () => {}

function CertificateValidation() {
  const [state, dispatch] = useAppState()

  function escapeCertificateDomain() {
    var host = window.location.hostname.replace('certificado.', '')
    window.location = 'https://' + host
  }

  useEffect(componentDidMount(dispatch, state), [])
  return (
    <>
      <span className='page-title'>Obter Confiabilidade por Certificado Digital</span>
      <p>Você pode aumentar o nível da sua conta gov.br para ouro utilizando certificado digital emitido por uma Autoridade Certificadora (AC), em conformidade com as regras estabelecidas pelo Comitê Gestor da ICP-Brasil.</p>
      <p>
        <a href='https://www.gov.br/iti/pt-br/assuntos/certificado-digital/certificado-digital' target='_blank' rel='noopener noreferrer'>
          Saiba Mais
        </a>
        &nbsp;|&nbsp;
        <a href='https://www.gov.br/iti/pt-br/assuntos/certificado-digital/como-obter' target='_blank' rel='noopener noreferrer'>
          Como obter
        </a>
      </p>
      <div className='action-group'>
        <div className='action-group-left'>
          <Link className='action-tertiary' to='/quero-este-nivel?nivel=ouro' onClick={() => escapeCertificateDomain()}>
            Voltar
          </Link>
        </div>
      </div>
    </>
  )
}

export default CertificateValidation
