import './style.css'
import React from 'react'
import Modal from 'react-modal'
import { useAppState } from '../AppContext'
import { setModalHowToObtainReliabilityVisibility } from './actions'
import { useHistory } from 'react-router-dom'

Modal.setAppElement('#root')

function ModalHowToObtainReliability() {
  let apiBaseUrl = 'https://' + window.location.hostname + '/api/reliabilities/'

  const [state, dispatch] = useAppState()
  const reliabilityInfo = state.reliabilityInfo.info
  const rel = reliabilityInfo
  let history = useHistory();

  const closeModal = () => {
    dispatch(setModalHowToObtainReliabilityVisibility(false))
    // Por algum motivo, ao entrar no modal, a url perde o query-param, então estou forçando aqui:
    if (rel.reliability.categoria >= 100 && rel.reliability.categoria < 200) history.push("?nivel=bronze");
    else if (rel.reliability.categoria >= 200 && rel.reliability.categoria < 300) history.push("?nivel=prata");
    else if (rel.reliability.categoria >= 300 && rel.reliability.categoria < 400) history.push("?nivel=ouro");
  }

  return (
    <Modal
      isOpen={state.modal_reliability_visibility.obtain_reliability}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
    >
      {rel &&
        <p key={rel.reliability.id}>
          <img src={apiBaseUrl + (rel.reliability.id) + '/image'} ></img>
          <p><strong>{rel.reliability.titulo}</strong></p>
          <p dangerouslySetInnerHTML={{ __html: rel.reliability.descricao }} />
          {rel.reliability.orientacoesHtml &&
            <div key={'div' + rel.reliability.id}>
              <p><strong>Orientações</strong></p>
              <p dangerouslySetInnerHTML={{ __html: rel.reliability.orientacoesHtml }} />
            </div>
          }
          <footer>
            <button className="action-secondary" onClick={closeModal}>Fechar</button>
          </footer>
        </p>
      }
    </Modal>
  )
}

export default ModalHowToObtainReliability
