import fetchApi from '../fetch-api'
import { API_PATH } from '../constants'
export const CHANGE_LOGGED_USER_ACTION = 'USER/CHANGE_LOGGED_USER'

export const changeLoggedUserData = user => ({
  type: CHANGE_LOGGED_USER_ACTION,
  user,
})

export const fetchLoggedUser = () => async (dispatch, state) => {
  let user = await fetchApi(API_PATH.endpoints.getUserInfo, { method: 'GET' })
  if (user) {
    user.id = user.sub
    sessionStorage.setItem('id', user.id)
    const photo = await fetchApi(API_PATH.endpoints.getUserInfoPhoto, { method: 'GET' }, true)
    if(photo){
      dispatch(changeLoggedUserData({ photoBase64: 'data:image/jpeg;base64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(photo))) }))
    }
  } else {
    sessionStorage.clear()
  }
  dispatch(changeLoggedUserData(user))
}

export const logoutUser = () => async dispatch => {
  sessionStorage.clear()
  window.location.href = API_PATH.endpoints.logout
}
