import React from "react";
import { Link } from "react-router-dom";

export default function Bronze() {
  return (
    <div className="card-level">
      <p>
        NÍVEL <b>BRONZE</b>
      </p>
      <img
        alt="Nível"
        src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`}
      />
      <ul className="advantages">
        <li>
          <b>Gratuito</b>
        </li>
        <li>Conta única</li>
        <li>Login sem senha</li>
        <li>Gerenciar uso de dados</li>
        <li className="dont">Prova de vida</li>
        <li className="dont">Verificação em duas etapas</li>
        <li className="dont">Compartilhar documentos</li>
        <li className="dont">Assinatura eletrônica</li>
        <li className="dont">Acesso a todos os serviços</li>
        <li className="dont">Nível máximo de segurança</li>
      </ul>
      <Link className="action-secondary" to="/quero-este-nivel?nivel=bronze">
        Ver nível
      </Link>
    </div>
  );
}
