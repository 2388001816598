import "./style.css";

import React, { useEffect } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useAppState } from "../AppContext";
import { useNewReliabilityState } from "../../commons/reliability-store/hooks/useNewReliabilityContext";
import { eraseReliability } from "../../commons/reliability-store/actions";
import {
  changeLevelData,
  setModalNewLevelNotificationVisibility
} from "./actions";

export default function ModalNewLevelNotification() {
  const [state, dispatch] = useAppState();
  const [
    newReliabilityState,
    newReliabilityDispatch
  ] = useNewReliabilityState();
  const { levelData } = state;

  useEffect(() => {
    if (
      newReliabilityState === "undefined" ||
      newReliabilityState == null ||
      newReliabilityState.length !== 3
    ) {
      dispatch(setModalNewLevelNotificationVisibility(false));
      return;
    }

    if (
      (newReliabilityState && !levelData.newReliability) ||
      levelData.newReliability != newReliabilityState
    ) {
      dispatch(
        changeLevelData({
          ...state.levelData,
          up: true,
          newReliability: newReliabilityState
        })
      );
    }
  }, [newReliabilityState, state.levelData]);

  const closeModal = () => {
    dispatch(setModalNewLevelNotificationVisibility(false));
    dispatch(
      changeLevelData({
        ...state.levelData,
        up: false,
        newReliability: ""
      })
    );
    newReliabilityDispatch(eraseReliability());
    if (window.location.hostname.startsWith("certificado.")) {
      const replacedHostname = window.location.hostname.replace(
        "certificado.",
        ""
      );
      window.location = `https://${replacedHostname}`;
      return;
    }
  };

  const levelIdToLevel = level =>
    ["Bronze".toUpperCase(), "Prata".toUpperCase(), "Ouro".toUpperCase()][
      level - 1
    ];

  const levelIdToColor = level =>
    ["#eb8184ff", "#7c7c7cff", "#fccf08ff"][level - 1];

  const levelIdToBadgeUrl = level => {
    const host = window.location.protocol + "//" + window.location.host;

    const imagePath = [
      "/cdn/images/bronze_level_icon.png",
      "/cdn/images/silver_level_icon.png",
      "/cdn/images/gold_level_icon.png"
    ][level - 1];

    return host + imagePath;
  };

  const reliabilityDescription = {
    "301": "Validamos seus dados na base de dados do SIGEPE",
    "602": "Validamos seus dados por meio do seu banco",
    "603": "Validamos seus dados por meio do seu banco",
    "604": "Validamos seus dados por meio do seu banco",
    "606": "Validamos seus dados por meio do seu banco",
    "605": "Validamos seus dados por meio do seu banco",
    "607": "Validamos seus dados por meio do seu banco",
    "608": "Validamos seus dados por meio do seu banco",
    "609": "Validamos seus dados por meio do seu banco",
    "610": "Validamos seus dados por meio do seu banco",
    "624": "Validamos seus dados por meio do seu banco",
    "618": "Validamos seus dados por meio do seu banco",
    "625": "Validamos seus dados por meio do seu banco",
    "626": "Validamos seus dados por meio do seu banco",
    "627": "Validamos seus dados por meio do seu banco",
    "801": "Validamos seus dados por meio de certificado digital (ICP-Brasil)"
  };

  return (
    <Modal
      isOpen={
        state.modal_new_level_notification_visibility.new_level_notification
      }
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="new-level-notification"
      id="modal-new-level-notification"
    >
      <>
        {levelData && levelData?.up && (
          <div className="custom-container">
            <div className="my-own-card">
              <section className="card-header">
                <img
                  src="images/party.png"
                  alt="Imagem de confetes de parabenização"
                />
                <div className="card-header-text">
                  <h3 className="overlay-congratulation-title">Parabéns!</h3>

                  <p className="congratulation-description">
                    {reliabilityDescription[levelData?.newReliability]}
                  </p>
                </div>
              </section>

              <section className="card-content">
                <div className="row">
                  <div className="image-column">
                    <img
                      src={levelIdToBadgeUrl(levelData?.level)}
                      alt="Level Icon"
                    />
                  </div>
                  <div className="info-column">
                    <p>
                      {"Sua conta agora é "}
                      <strong
                        style={{ color: levelIdToColor(levelData?.level) }}
                      >
                        {levelIdToLevel(levelData?.level)}
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row help-row">
                  <div className="icon-column">
                    <FontAwesomeIcon icon={faInfoCircle} size="10x" />
                  </div>
                  <div className="info-text-column">
                    <p>
                      Se você estava tentando acessar agum serviço e precisou
                      aumentar o nível da conta, você pode retornar e logar em
                      "Entrar com gov.br".
                    </p>
                  </div>
                </div>
              </section>

              <section className="card-footer">
                <button className="action-primary" onClick={closeModal}>
                  Concluir
                </button>

                <a href="https://www.gov.br/governodigital/pt-br/conta-gov-br/saiba-mais-sobre-os-niveis-da-conta-govbr/saiba-mais-sobre-os-niveis-da-conta-govbr">
                  <FontAwesomeIcon icon={faInfoCircle} size="10x" />
                  Saiba mais sobre os <strong>níveis</strong> da conta gov.br.
                </a>
              </section>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
}
