import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState, useQuery } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import { changeFormData } from '../../home/actions'
const componentDidMount = (dispatch) => () => {
  dispatch(
    changeInfoTela({
      title: 'Obter Confiabilidade por Certificado Digital',
      breadcrumbs: [{ label: 'Nível da conta', link: '/' }, { label: 'Aumentar nível da conta', link: '/quero-este-nivel?nivel=ouro' }, { label: 'Obter Confiabilidade por Certificado Digital' }],
    })
  )
  return () => {
    dispatch(changeFormData({ questions: [], formData: {}, biovalid: {} }))
  }
}

function Certificate() {
  const [state, dispatch] = useAppState()

  const paramObj = useQuery()
  const qpAcao = paramObj.get('acao')

  const isUpdateCertificateReliability = qpAcao === 'atualizar'

  useEffect(componentDidMount(dispatch, state), [])

  const avancar = () => {
    window.location.hostname.startsWith('certificado.')
      ? (window.location.href = `${window.location.protocol}//${window.location.hostname}/ler-certificado-digital?accountId=${state.logged_user.id}`)
      : (window.location.href = `${window.location.protocol}//certificado.${window.location.hostname}/ler-certificado-digital?accountId=${state.logged_user.id}`)
  }

  return (
    <>
      <span className='page-title'>{!isUpdateCertificateReliability ? 'Obter' : 'Atualizar'} Confiabilidade por Certificado Digital</span>

      <p>Você pode aumentar o nível da sua conta gov.br para ouro utilizando certificado digital emitido por uma Autoridade Certificadora (AC), em conformidade com as regras estabelecidas pelo Comitê Gestor da ICP-Brasil.</p>
      <p>
        <a href='https://www.gov.br/iti/pt-br/assuntos/certificado-digital/certificado-digital' target='_blank' rel='noopener noreferrer'>
          Saiba Mais
        </a>
        &nbsp;|&nbsp;
        <a href='https://www.gov.br/iti/pt-br/assuntos/certificado-digital/como-obter' target='_blank' rel='noopener noreferrer'>
          Como obter
        </a>
      </p>
      <div className='action-group'>
        <div className='action-group-left'>
          <Link className='action-tertiary' to='/quero-este-nivel?nivel=ouro'>
            Voltar
          </Link>
        </div>
        <div>
          <button className='action-primary' onClick={avancar}>
            Ler Certificado Digital
          </button>
        </div>
      </div>
    </>
  )
}

export default Certificate
