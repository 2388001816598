import { API_PATH } from '../../../commons/constants'
import fetchApi from '../../../commons/fetch-api'
import { changeFormData } from '../../home/actions'

export const CARROSEL_DE_PERGUNTAS = 'KBA/CARROSEL_DE_PERGUNTAS'

export const carrosselDePerguntas = data => ({
  type: CARROSEL_DE_PERGUNTAS,
  data
})


export const retrieveKbaQuestions = () => async (dispatch, state) => {
  var questions = await fetchApi(API_PATH.endpoints.postCreateKbaQuestions, { method: 'POST' })  
  dispatch(
    changeFormData({
      ...state,
      formData: questions,
    })
  )
}

export const validateKbaAnswers = (answers, token) => async (dispatch, state) => {

  const pathRequest = API_PATH.endpoints.putReliability
    .replace(':accountId', state.logged_user.id)
    .replace(':reliabilityId', 'person-info-kba')
  
  const response = await fetchApi(pathRequest, {
    method: 'PUT',
    body: JSON.stringify({answers: answers, token: token}),
  })
  
  if (response) {
    // dispatch(
    //   reliabilityAcquired(true)
    // )
    //TODO REDIRECT DECENTE
    window.location = '/'
    
  }
}

