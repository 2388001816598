import { API_PATH } from '../../../commons/constants'
import { changeFormData } from '../../home/actions'
import fetchApi from '../../../commons/fetch-api'
import { changeMessageDelay } from '../../layout/actions'

function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const createFacialBiometricsQrCode = (reliabilityId, currentPath) => async (dispatch, state) => {
  const pathRequest = API_PATH.endpoints.postCreateFacialBiometricsQrCode
    .replace(':accountId', state.logged_user.id)
    .replace(':reliabilityId', reliabilityId)

  const biovalid = await fetchApi(pathRequest, {method: 'POST' })
  if(biovalid){
    dispatch(changeFormData({biovalid}))

    const url = API_PATH.endpoints.putValidateFacialBiometricsQrCode
      .replace(':accountId', state.logged_user.id)
      .replace(':biovalidPin', biovalid.pin)

    var res
    var callStoped = false
    for(var i = 0; i < 15 && !callStoped; i++){
      await sleep(10000).then(() => {})
      res = await fetchApi(url, {method: 'PUT' }, false, currentPath)
      if(!res){
        continue
      }
      if(res && res.stopCall){
        i = 10
        callStoped = true
        return
      }
      i = 10
      if(res.reliabilityAcquired){
        window.location = '/'
      }
      else{
        biovalid.reliabilityAcquired = res.reliabilityAcquired
        biovalid.biometricsValidationSource = res.biometricsValidationSource
        biovalid.biovalidValidated = true
        dispatch(changeFormData({ biovalid }))
      }
    }
    if(!biovalid.biovalidValidated && !callStoped){
      dispatch(changeFormData({ biovalid:{
        biovalidValidated: false, reliabilityAcquired: false,  biometricsValidationSource: undefined
      }}))
      dispatch(changeMessageDelay({type: 'ERROR', text: 'Rosto não reconhecido. Por favor, tente novamente.'}))
    }
  }
}



export const validateLastDriverLicenseDate = (date, validationSource) => async (dispatch, state) => {

  let formatted_date = window.formatDate(date)
  const pathRequest = API_PATH.endpoints.putValidateDriverLicenseDate
    .replace(':accountId', state.logged_user.id)

  const biovalid = await fetchApi(pathRequest, {method: 'PUT', body: JSON.stringify({ biometricsValidationSource: validationSource, lastDriverLicenseIssueDate: formatted_date }) })
  if(biovalid){
    window.location = '/'
  }
}