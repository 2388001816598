import "./style.css";

import React from "react";
import Modal from "react-modal";
import { useAppState } from "../AppContext";
import { setModalAcquiredReliabilityVisibility } from "./actions";
import { useHistory } from "react-router-dom";
import { getBankImage } from "../../commons/util";

export default function ModalAcquiredReliability() {
  let apiBaseUrl = "https://" + window.location.hostname + "/api/reliabilities/";

  const [state, dispatch] = useAppState();
  const reliability = state.userReliabilitiesByLevel.reliability;
  let rel = {};
  let history = useHistory();

  if (reliability) {
    rel = reliability.rel;
  }

  const closeModal = () => {
    dispatch(setModalAcquiredReliabilityVisibility(false));
    // Por algum motivo, ao entrar no modal, a url perde o query-param, então estou forçando aqui:
    if (rel.reliability.categoria >= 100 && rel.reliability.categoria < 200) history.push("?nivel=bronze");
    else if (rel.reliability.categoria >= 200 && rel.reliability.categoria < 300) history.push("?nivel=prata");
    else if (rel.reliability.categoria >= 300 && rel.reliability.categoria < 400) history.push("?nivel=ouro");
  };

  const updateReliability = rel => {
    history.push(rel.reliability.urlAquisicao + "?acao=atualizar");
  };

  return (
    <Modal
      isOpen={state.modal_reliability_visibility.acquired_reliability}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
      id="modal-acquired-reliability">
      {
        rel && rel.reliability &&
        <>
          {(rel.reliability.id < 601 || rel.reliability.id > 700) && <img src={apiBaseUrl + rel.reliability.id + "/image"}></img>}
          {(rel.reliability.id > 601 && rel.reliability.id < 700) && <img key={rel.reliability.id} src={getBankImage(rel.reliability.id)}></img>}

          {rel.reliability.id === "801" &&
            <>
              <p>Validação dos seus dados com Certificado Digital compatível com ICP-Brasil.</p>
              <p><b>Seus dados foram validados utilizando o certificado digital em {rel.updateDate}.</b></p>
              <p>Se precisar atualizar o seu certificado digital, clique em “Atualizar”.</p>
            </>}

          {rel.reliability.id === "701" &&
            <>
              <p>Esse reconhecimento facial é realizado pelo aplicativo gov.br para conferência da sua foto nas bases da Justiça Eleitoral, caso você possua biometria cadastrada no TSE.</p>
              <p><b>O último reconhecimento facial realizado por você foi validado em {rel.updateDate}.</b></p>
              <p>Se precisar atualizar o seu reconhecimento facial, clique em “Atualizar”.</p>
            </>}

          {(rel.reliability.categoria === "204") &&
            <>
              <p>Seus dados foram validados na base da(o) <b>{rel.reliability.titulo}</b>. Essa validação garante o nível <b>prata</b> da conta gov.br. </p>
              <p><b>Seus dados foram validados via internet banking em {rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "504" &&
            <>
              <p>Validação e autenticação do cadastro via balcão gov.br</p>
              <p>Seus dados foram validados via balcão gov.br em <b>{rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "502" &&
            <>
              <p>Seus dados foram validados na base do Registro Nacional de Condutores Habilitados (RENACH) da SENATRAN. Essa validação é feita presencialmente em uma agência do DETRAN e garante o nível <b>bronze</b> da conta gov.br. </p>
              <p><b>Seus dados foram validados via cadastro presencial no SENATRAN/DETRAN em {rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "501" &&
            <>
              <p>Seus dados foram validados na base da Previdência Social (INSS). Essa validação garante o nível <b>bronze</b> da conta gov.br.</p>
              <p><b>Seus dados foram validados via cadastro presenial nas agências do INSS em {rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "401" &&
            <>
              <p>Esse reconhecimento facial é realizado pelo <b>aplicativo gov.br</b> para conferência da sua foto nas bases do SENATRAN, caso você possua Carteira de Habilitação (CNH).</p>
              <p><b>O último reconhecimento facial realizado por você foi validado em {rel.updateDate}.</b></p>
              <p>Se precisar atualizar o seu reconhecimento facial, clique em “Atualizar”.</p>
            </>}

          {rel.reliability.id === "301" &&
            <>
              <p>Essa validação dos seus dados é realizada <b>com usuário e senha do SIGEPE</b>, se você for servidor público federal.</p>
              <p><b>Seus dados foram validados na base do SIGEPE em {rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "201" &&
            <>
              <p>A sua conta foi criada por meio da plataforma gov.br e seus dados foram validados na Receita Federal. Essa validação garante o nível <b>bronze</b> da conta gov.br</p>
              <p><b>Seus dados foram validados via questionário da Receita Federal em {rel.updateDate}.</b></p>
            </>}

          {rel.reliability.id === "101" &&
            <>
              <p>Seus dados foram validados na base da Previdência Social (INSS). Essa validação garante o nível <b>bronze</b> da conta gov.br.</p>
              <p><b>Seus dados foram validados via formulário on-line do INSS em {rel.updateDate}.</b></p>
            </>}

          <footer>
            <button className="action-secondary" onClick={closeModal}>Fechar</button>
            {(rel.reliability.id === "801" || rel.reliability.id === "701" || rel.reliability.id === "401") &&
              <button className="action-primary" onClick={() => updateReliability(rel)}>Atualizar</button>}
          </footer>
        </>
      }
    </Modal>
  );
}
