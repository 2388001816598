import React from 'react'
import { Link } from 'react-router-dom'

export default function Card(props) {
  const {
    linkTo,
    onClick,
    imgSrc,
    title,
    description,
    css="card",
  } = props

  return (
    <>
      <Link to={linkTo} onClick={onClick}>
        <div className={css}>
          <img className="card-icon" src={imgSrc} alt="card" />
          <p>{title}</p>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </Link>
    </>
  )
}
