import React, { useEffect, useState } from "react";
import { useAppState } from "../AppContext";
import { useHistory } from "react-router-dom";
import { changeInfoTela } from "../layout/actions";
import Card from "../../commons/Card";
import {
  retrieveBankingProviders,
  changeReliabilityInfo,
  retrieveUserReliabilities,
  setModalAcquiredReliabilityVisibility,
  setModalHowToObtainReliabilityVisibility,
  viewUserReliabilitiesById
} from "./actions";
import ModalAcquiredReliability from "./ModalAcquiredReliability";
import Modal from "react-modal";

Modal.setAppElement("#root");

const componentDidMount = dispatch => () => {
  window.scrollTo(0, 0);
  dispatch(
    changeInfoTela({
      title: "Como aumentar o nível da sua conta",
      breadcrumbs: [
        { label: "Aumentar nível da conta", link: "/" },
        { label: "Como aumentar o nível da sua conta" }
      ]
    })
  );
  dispatch(retrieveUserReliabilities());
  dispatch(retrieveBankingProviders());
};

export default function BancosCredenciados() {
  const [state, dispatch] = useAppState();

  let [{ bankingProviders, reliabilities }] = useAppState();
  let lvls = reliabilities.reliabilities;
  let allMyReliabilities = [];

  useEffect(componentDidMount(dispatch), []);

  let apiBaseUrl =
    "https://" +
    window.location.hostname +
    "/api/aquisicao-confiabilidade-bancos-credenciados";
  let history = useHistory();

  const viewUserReliability = rel => {
    dispatch(setModalAcquiredReliabilityVisibility(true));
    dispatch(viewUserReliabilitiesById({ rel }));
  };

  const obtainReliabilityFromProvider = provider => {
    let relId =
      bankingProviders.bankingProviders.providerToReliability[provider];
    let rel = lvls[1].categories
      .filter(c => c.category.id == "204")[0]
      .reliabilities.filter(r => r.reliability.id === relId)[0];

    if (rel.reliability.nai) {
      setModalBankNai(true);
      setBankNai(rel.reliability);
    } else if (allMyReliabilities.includes(rel.reliability.id)) {
      viewUserReliability(rel);
    } else if (rel.reliability.urlAquisicao !== undefined) {
      history.push(rel.reliability.urlAquisicao);
    } else {
      openHowToObtainReliabilityModal(rel.reliability);
    }
  };

  const openHowToObtainReliabilityModal = reliability => {
    dispatch(setModalHowToObtainReliabilityVisibility(true));
    dispatch(changeReliabilityInfo({ reliability }));
  };

  const [modalAcessoSeguroIsOpen, setModalAcessoSeguroIsOpen] = useState(false);
  const [modalBankNai, setModalBankNai] = useState(false);
  const [bankNai, setBankNai] = useState({});

  return (
    <>
      {/* Obtendo as minhas reliabilities */}
      {lvls &&
        lvls
          .filter(l1 => l1.anyAcquired)
          .map(l2 => {
            for (let i = 0; i < l2.categories.length; i++) {
              l2.categories[i].reliabilities
                .filter(r1 => r1.acquired)
                .map(r2 => allMyReliabilities.push(r2.reliability.id));
            }
          })}

      <span className="page-title">Bancos credenciados</span>
      <span className="page-description">
        Você pode aumentar o nível da sua conta gov.br para prata, fazendo o
        login no internet banking do seu banco. Não teremos acesso aos seus
        dados bancários, apenas nome e CPF.
      </span>

      <div className="home-cards">
        {bankingProviders &&
          bankingProviders?.bankingProviders?.providers?.map(provider => (
            <>
              <Card
                id={provider.id}
                key={provider.id}
                css="card-bank"
                onClick={() => obtainReliabilityFromProvider(provider.id)}
                imgSrc={provider.iconUrl}
                description={provider.title}
              />
            </>
          ))}
      </div>
      <a id="info" onClick={() => setModalAcessoSeguroIsOpen(true)}>
        <i className="fa fa-question-circle-o" aria-hidden="true"></i>O acesso
        pelo meu banco é seguro?
      </a>

      <Modal
        id="acesso-bancos-seguro"
        isOpen={modalAcessoSeguroIsOpen}
        contentLabel="Nome"
        className="modal"
      >
        <p>
          <b>O ACESSO PELO MEU BANCO É SEGURO?</b>
        </p>
        <span>
          <b>Sim!</b> Aumentar o nível da sua conta gov.br por meio de um banco
          credenciado é um processo seguro, pois{" "}
          <b>
            toda a transação ocorre diretamente no ambiente do seu internet
            banking
          </b>
          .
        </span>
        <span>
          Você é redirecionado para a página do seu banco e lá informa os dados
          solicitados para fazer o login. O banco, por sua vez, informa para a
          plataforma gov.br apenas os dados do seu nome, CPF e a confirmação de
          que é você mesmo quem está logando naquele banco.
        </span>
        <span>
          Esse processo é seguro para aumentar o nível da sua conta gov.br, pois
          o banco garante que é você quem está se identificando pelo internet
          banking.
        </span>
        <span>
          <b>Não temos acesso a nenhum dos seus dados bancários.</b>
        </span>
        <span>
          Você faz o login direto na página do seu internet banking e, ao final
          do processo, o nível da sua conta será PRATA.
        </span>
        <footer>
          <button
            className="action-secondary"
            onClick={() => setModalAcessoSeguroIsOpen(false)}
          >
            Fechar
          </button>
        </footer>
      </Modal>

      <Modal
        id="bancos-nai"
        isOpen={modalBankNai}
        contentLabel="Nome"
        className="modal"
      >
        <img src={apiBaseUrl + bankNai.id + "/image"}></img>
        <p dangerouslySetInnerHTML={{ __html: bankNai.orientacoesHtml }} />
        <footer>
          <button
            className="action-secondary"
            onClick={() => setModalBankNai(false)}
          >
            Fechar
          </button>
        </footer>
      </Modal>

      <ModalAcquiredReliability />
    </>
  );
}
