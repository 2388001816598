import React from "react";

export default function MyLevel({ maxLevel }) {

  return (
    <div className="card-info-level">
      <p style={{ fontSize: 14 }}>
        SUA CONTA GOV.BR É NÍVEL{' '}
        <strong>
          {maxLevel === 1 && 'BRONZE'}
          {maxLevel === 2 && 'PRATA'}
          {maxLevel === 3 && 'OURO'}
        </strong>
      </p>
      {maxLevel === 1 && (
        <img id="imglevel" style={{width:'50%'}} alt="Nível" src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`} />
      )}
      {maxLevel === 2 && (
        <img id="imglevel" style={{width:'50%'}} alt="Nível" src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`} />
      )}
      {maxLevel === 3 && (
        <img id="imglevel" style={{width:'50%'}} alt="Nível" src={`${process.env.REACT_APP_URL_ASSETS}/images/gold_horizontal.png`} />
      )}
    </div>
  )

}